import { LOGIN_F, LOGIN_S, LS_AUTHTOKEN, LS_USER } from "../constants";

//To concate the path for the public folder
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

// Fun used for setting up the common header for axios through out the app and rehydrate the redux store
export const setupAxios = (axios, store) => {

  if (localStorage.getItem(LS_AUTHTOKEN) && localStorage.getItem(LS_USER)) {

    const token = JSON.parse(localStorage.getItem(LS_AUTHTOKEN));
    const userData = JSON.parse(localStorage.getItem(LS_USER));

    if (token) {
      store.dispatch({ type: LOGIN_S, payload: { data: userData } });
    } else {
      store.dispatch({ type: LOGIN_F, payload: {} });
    }
  }

  // It's used to rehydrate redux auth data when page is refreshed


  // It's used to intercept all the axios api response
  axios.interceptors.response.use(null, (err) => {
    if (err.response) {
      if (err.response.status === 403 || err.response.status === 401) {
        store.dispatch({ type: LOGIN_F });
        return Promise.reject(err);
      } else {
        return Promise.reject(err);
      }
    } else if (err.request) {
      return Promise.reject({
        response: {
          data: {
            message: "Something went wrong, Please try again later!!!",
          },
        },
      });
    }
  });
};

// Encrypt Function
export const encrypt = (param) => {
  if (param) return btoa(param);
  else return "";
};

// Decrypt Function
export const decrypt = (param) => {
  if (param) return atob(param);
  else return "";
};

// Debouncing for input search 
export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1000);
  };
};

export function rgbaToHex(rgba) {
  const [r, g, b] = rgba.match(/\d+/g);
  const hexR = Number(r).toString(16).padStart(2, '0');
  const hexG = Number(g).toString(16).padStart(2, '0');
  const hexB = Number(b).toString(16).padStart(2, '0');
  return `#${hexR}${hexG}${hexB}`;
}

// Function to convert a hex code to RGBA format
const percentages = ['0%', '35%', '100%']
export function hexToRGBA(hex, i) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, 1) ${percentages[i]}`;
}

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
  return cleaned.length === 10
    ? `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`
    : phoneNumber;
};

export const capitalizeFirstLetter = (text) => {
  console.log('text: ', text);
  if (!text) return '-';
  return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
};

export const getSortOrder = (order) => {
  switch (order) {
    case 'descend':
      return 'desc';
    case 'ascend':
      return 'asc';
    default:
      return undefined;
  }
};
